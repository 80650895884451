<h1 mat-dialog-title [translate]="'FEATURE_REQUIRES_PAID_PLAN'"></h1>
<div mat-dialog-content class="full-width text-center">
  <div class="pad-bottom">
    {{ "MAX_PLAYERS_YOUR_PLAN" | translate }}:
    <span class="tc-bv-orange">{{ clubServ.getPlayerLimit() }}</span>
  </div>
</div>
<div mat-dialog-actions>
  <button
    id="btn_go_subscription"
    mat-button
    td-menu-button
    (click)="goSubscription()"
    color="accent"
  >
    {{ "CHANGE_PLAN" | translate }}
  </button>
</div>
