import { Component, AfterViewInit, NgZone } from "@angular/core";
import { ProfileService } from "./providers/profile.service";
import { TranslateService } from "@ngx-translate/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigService } from "./providers/config.service";
import { ActivatedRoute } from "@angular/router";
import firebase from "firebase/app";
import { MatDialog } from "@angular/material/dialog";
import { DisconnectedComponent } from "./shared/disconnected/disconnected.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  connectedInit: boolean = false;
  constructor(
    private profServ: ProfileService,
    private translate: TranslateService,
    public icon: MatIconRegistry,
    public sani: DomSanitizer,
    private configServ: ConfigService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private zone: NgZone
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    translate.use("en");
    icon.addSvgIcon(
      "facebook",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/facebook.svg")
    );
    icon.addSvgIcon(
      "twitter",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/twitter.svg")
    );
    icon.addSvgIcon(
      "calculator",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/calculator.svg")
    );
    icon.addSvgIcon(
      "rebuy",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/jetons-orange.svg")
    );
    icon.addSvgIcon(
      "addon",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/jetons-bleu.svg")
    );
    icon.addSvgIcon(
      "balance",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/balance.svg")
    );
    icon.addSvgIcon(
      "add-table",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/add-table.svg")
    );
    icon.addSvgIcon(
      "level",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/level.svg")
    );
    icon.addSvgIcon(
      "break-table",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/break-table.svg")
    );
    icon.addSvgIcon(
      "draw-seats",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/draw-seats.svg")
    );
    icon.addSvgIcon(
      "multiplier",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/multiplier.svg")
    );
    icon.addSvgIcon(
      "down-chevron",
      sani.bypassSecurityTrustResourceUrl("/assets/icons/icon-chevron-down.svg")
    );
    //icon.addSvgIcon('trophy', sani.bypassSecurityTrustResourceUrl('/assets/icons/trophy-variant.svg'));
  }
  ngAfterViewInit() {
    const connectedRef = firebase.database().ref(".info/connected");
    let th: any = this;
    connectedRef.on("value", function (snap) {
      let state: boolean = snap.val();
      if (!th.connectedInit) {
        th.connectedInit = state;
      } else if (state)
        th.zone.run(() => {
          th.dialog.closeAll();
        });
      else
        th.zone.run(() => {
          th.dialog.open(DisconnectedComponent, { disableClose: true });
        });
    });
  }
}
