import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  fbFunc: any;
  constructor(private auth: AuthService) {
    this.fbFunc = firebase.functions();
  }
  profRegister(uid: string, tournID: string) {
    let f = this.fbFunc.httpsCallable("profRegister");
    f({
      uid: uid,
      tournID: tournID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  profUnregister(uid: string, tournID: string) {
    let f = this.fbFunc.httpsCallable("profUnregister");
    f({
      uid: uid,
      tournID: tournID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  profUndoElimination(uid: string, tournID: string) {
    let f = this.fbFunc.httpsCallable("profUndoElimination");
    f({
      uid: uid,
      tournID: tournID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  profSetFinish(uid: string, tournID: string, finish: number) {
    let f = this.fbFunc.httpsCallable("profSetFinish");
    f({
      uid: uid,
      tournID: tournID,
      finish: finish,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  deleteTournament(tournID: string, clubID: string) {
    let f = this.fbFunc.httpsCallable("deleteTournament");
    return f({
      tournID: tournID,
      clubID: clubID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  updateClub(params: any) {
    let f = this.fbFunc.httpsCallable("updateClub");
    f(params).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  clubRemoveMemberImages(clubID: string, imageType: string) {
    let f = this.fbFunc.httpsCallable("clubRemoveMemberImages");
    f({
      clubID: clubID,
      imageType: imageType,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  profRemoveClub(clubID: string, uid: string) {
    let f = this.fbFunc.httpsCallable("profRemoveClub");
    f({
      clubID: clubID,
      uid: uid,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  deleteClub(clubID: string) {
    let f = this.fbFunc.httpsCallable("deleteClub");
    return f({
      clubID: clubID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  recalculateLeagueStandings(leagueID: string) {
    let f = this.fbFunc.httpsCallable("recalculateLeagueStandings");
    f({
      leagueID: leagueID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  calcTournPoints(tournID: string, uid: string) {
    let f = this.fbFunc.httpsCallable("calcTournPoints");
    f({
      tournID: tournID,
      uid: uid,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  deleteLeague(leagueID: string, clubID: string) {
    let f = this.fbFunc.httpsCallable("deleteLeague");
    return f({
      leagueID: leagueID,
      clubID: clubID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  updateLeagueTournNames(leagueID: string, leagueName: string, clubID: string) {
    let f = this.fbFunc.httpsCallable("updateLeagueTournNames");
    f({
      leagueID: leagueID,
      leagueName: leagueName,
      clubID: clubID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  deleteRequest(clubID: string, uid: string) {
    let f = this.fbFunc.httpsCallable("deleteRequest");
    return f({
      clubID: clubID,
      uid: uid,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  joinClub(clubID: string, profile: any, inviteID?: string) {
    let f = this.fbFunc.httpsCallable("joinClub");
    let req: any = {
      clubID: clubID,
      profile: profile,
    };
    if (inviteID) req.inviteID = inviteID;
    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  appLog(where: string, when: any, fName: string, error: string, params: any) {
    let f = this.fbFunc.httpsCallable("appLog");
    f({
      where: where,
      when: when,
      fName: fName,
      error: error,
      params: params,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  geoLoc() {
    let f = this.fbFunc.httpsCallable("geoLoc");
    return f().catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  setStripeCards(uid: string, stripeID: string) {
    let f = this.fbFunc.httpsCallable("setStripeCards");
    let req: any = {
      uid: uid,
      stripeID: stripeID,
    };

    f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  addCard(uid: string, stripeID: string, source: string) {
    let f = this.fbFunc.httpsCallable("addCard");
    let req: any = {
      uid: uid,
      stripeID: stripeID,
      source: source,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  deleteCard(uid: string, stripeID: string, cardID: string) {
    let f = this.fbFunc.httpsCallable("deleteCard");
    let req: any = {
      uid: uid,
      stripeID: stripeID,
      cardID: cardID,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  changeDefaultCard(uid: string, stripeID: string, cardID: string) {
    let f = this.fbFunc.httpsCallable("changeDefaultCard");
    let req: any = {
      uid: uid,
      stripeID: stripeID,
      cardID: cardID,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  reactivateSubscription(
    uid: string,
    subID: string,
    planID: string,
    code: string
  ) {
    let f = this.fbFunc.httpsCallable("reactivateSubscription");
    let req: any = {
      uid: uid,
      subID: subID,
      planID: planID,
      code: code,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  cancelSubscription(uid: string, subID: string, code: string) {
    let f = this.fbFunc.httpsCallable("cancelSubscription");
    let req: any = {
      uid: uid,
      subID: subID,
      code: code,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  prorate(profile: any, change: any) {
    let f = this.fbFunc.httpsCallable("prorate");
    let req: any = {
      profile: profile,
      change: change,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  changeSubscription(profile: any, change: any) {
    let f = this.fbFunc.httpsCallable("changeSubscription");
    let req: any = {
      profile: profile,
      change: change,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  payInvoice(uid: string, stripeID: string, source: string) {
    let f = this.fbFunc.httpsCallable("payInvoice");
    let req: any = {
      uid: uid,
      stripeID: stripeID,
      source: source,
    };

    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  mergeMember(sourceID: string, targetID: string, clubID: string) {
    let f = this.fbFunc.httpsCallable("mergeMember");
    let req: any = {
      sourceID: sourceID,
      targetID: targetID,
      clubID: clubID,
    };
    return f(req).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
  createContactSIB(data: any) {
    let f = this.fbFunc.httpsCallable("createContactSIB");
    return f(data).catch((error) => {
      console.log(error.code);
      console.log(error.message);
    });
  }
  setPreference(data: any) {
    let f = this.fbFunc.httpsCallable("setPreference");
    return f(data).catch((error) => {
      console.log(error.code);
      console.log(error.message);
    });
  }
  sendEvent(messageId: string, profile: any) {
    if (profile && profile.locationData) {
      let f = this.fbFunc.httpsCallable("sendEvent");
      let data: any = {
        messageId: messageId,
        city: profile.locationData.city,
        country: profile.locationData.country,
      };
      if (profile.locationData.state) data.state = profile.locationData.state;
      if (profile.locationData.location)
        data.location = profile.locationData.location;
      return f(data).catch((error) => {
        console.log(error.code);
        console.log(error.message);
      });
    }
  }
  quitClub(clubID: string) {
    let f = this.fbFunc.httpsCallable("quitClub");
    return f({
      clubID: clubID,
    }).catch(function (error) {
      console.log(error.code);
      console.log(error.message);
    });
  }
}
