import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capFirstLetters",
})
export class CapFirstLettersPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const words = value.split(" ");

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }
}
