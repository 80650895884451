import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
@Component({
  selector: "app-close-dialog-button",
  templateUrl: "./close-dialog-button.component.html",
  styleUrls: ["./close-dialog-button.component.scss"],
})
export class CloseDialogButtonComponent implements OnInit {
  @Input() label: string;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}
  close() {
    this.dialog.closeAll();
  }
}
