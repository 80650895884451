import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs";

interface Location {
  latitude: string;
  longitude: string;
}

interface Event {
  city: string;
  country: string;
  location: Location;
  messageId: string;
  state?: string;
  timestamp: number;
}

@Injectable({
  providedIn: "root",
})
export class EventService {
  lastTenAF: Observable<any[]>;
  lastTen: any[];
  allEventsAF: Observable<Event[]>;
  announcementAF: Observable<any>;
  announcement: Announcement;
  public uniqueLocations: Event[] = [];

  constructor(db: AngularFireDatabase) {
    this.lastTenAF = db
      .list<Event>("/events", (ref) =>
        ref.orderByChild("timestamp").limitToLast(30)
      )
      .valueChanges();
    this.lastTenAF.subscribe((events) => {
      this.lastTen = [...events].reverse();
    });

    // This subscription fetches ALL events
    this.allEventsAF = db
      .list<Event>("/events", (ref) => ref.orderByChild("timestamp"))
      .valueChanges();
    this.allEventsAF.subscribe((events) => {
      this.updateUniqueLocations(events);
    });
    this.announcementAF = db
      .object<Announcement>("/announcement")
      .valueChanges();
    this.announcementAF.subscribe((announcement) => {
      if (
        announcement &&
        announcement.text &&
        announcement.url &&
        announcement.ctaText
      )
        this.announcement = this.truncateAnnouncementText(announcement);
      else this.announcement = null;
    });
  }
  private updateUniqueLocations(events: Event[]): void {
    const eventsWithLocation = events.filter(
      (event) =>
        event.location?.latitude &&
        event.location?.longitude &&
        !(
          parseFloat(event.location.latitude) == 0 &&
          parseFloat(event.location.longitude) == 0
        )
    );

    // Group events by location coordinates
    const locationGroups = eventsWithLocation.reduce((groups, event) => {
      const key = `${event.location.latitude},${event.location.longitude}`;
      if (!groups[key] || groups[key].timestamp < event.timestamp) {
        groups[key] = event;
      }
      return groups;
    }, {} as { [key: string]: Event });

    // Convert grouped events back to array
    this.uniqueLocations = Object.values(locationGroups);
  }

  private truncateAnnouncementText(announcement: Announcement): Announcement {
    const maxLength = 78;
    const totalLength = announcement.ctaText.length + announcement.text.length;

    if (totalLength > maxLength) {
      // Calculate how much we need to truncate
      const truncateLength = totalLength - maxLength + 3; // Add 3 for the ellipsis

      // Truncate the text and add an ellipsis
      announcement.text = `${announcement.text.substring(
        0,
        announcement.text.length - truncateLength
      )}...`;
    }

    return announcement;
  }
}

export interface Announcement {
  text: string;
  url: string;
  ctaText: string;
}
