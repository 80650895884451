import { Injectable } from "@angular/core";
import { Howl, Howler } from "howler";
@Injectable()
export class AudioService {
  sounds: any;
  constructor() {}
  init() {
    if (!this.sounds) {
      var sounds = {
        change: new Howl({
          src: ["/assets/snd/change.mp3", "/assets/snd/change.ogg"],
          onplayerror: function () {
            console.log("player error change sound");
            sounds.change.once("unlock", function () {
              sounds.change.play();
            });
          },
          html5: true,
        }),
        minute: new Howl({
          src: ["/assets/snd/one-minute.mp3", "/assets/snd/one-minute.ogg"],
          onplayerror: function () {
            console.log("player error minute sound");
            sounds.minute.once("unlock", function () {
              sounds.minute.play();
            });
          },
          html5: true,
        }),
        second: new Howl({
          src: ["/assets/snd/one-second.mp3", "/assets/snd/one-second.ogg"],
          onplayerror: function () {
            console.log("player error second sound");
            sounds.second.once("unlock", function () {
              sounds.second.play();
            });
          },
          html5: true,
        }),
      };
      this.sounds = sounds;
    }
  }
  play(sound: string) {
    if (this.sounds[sound] && this.sounds[sound].play)
      this.sounds[sound].play();
  }
}
