<div #growWrapper layout="column" layout-align="center center" layout-fill>
  <div class="def-size" [ngStyle]="textStyle">
    <span #growText>
      <span *ngIf="leadIcon"
        ><mat-icon>{{ leadIcon }}</mat-icon
        >&nbsp;</span
      >{{ textString }}
    </span>
  </div>
</div>
