<h3 [translate]="'VERIFICATION_SENT'">
  A verification email has been sent to your inbox. Please click on the link in
  the message to complete the account creation process
</h3>
<button id="btn_resend_verification" mat-raised-button (click)="verifyEmail()">
  <span [translate]="'RESEND_VERIFICATION'"></span>
</button>
<button mat-raised-button color="accent" (click)="logout()">
  <span [translate]="'LOGOUT'"></span>
</button>
