import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TdDialogService } from "@covalent/core/dialogs";
import { saveAs } from "file-saver";
@Injectable()
export class UtilService {
  loading: boolean = false;
  constructor(
    private dialogServ: TdDialogService,
    private translate: TranslateService
  ) {}

  alertDialog(message: string) {
    this.translate.get([message, "CLOSE"]).subscribe((res: any) => {
      this.dialogServ.openAlert({
        message: res[message],
        closeButton: res["CLOSE"],
      });
    });
  }
  isError(result: any) {
    if (result && result.data && result.data.code && result.data.message)
      return true;
    else return false;
  }
  errorDialog(error: any) {
    this.dialogServ.closeAll();
    this.translate.get([error.code, "CLOSE"]).subscribe((res: any) => {
      this.dialogServ.openAlert({
        message: res[error.code] || error.message,
        closeButton: res["CLOSE"],
      });
    });
  }
  extractName(email: string) {
    if (email && email.length && email.split) return email.split("@")[0];
    else return email;
  }
  shedTime(ts: number) {
    let retDate = new Date(ts);
    retDate.setUTCHours(0);
    retDate.setUTCMinutes(0);
    retDate.setUTCSeconds(0);
    retDate.setUTCMilliseconds(0);
    let rettime = retDate.getTime();
    return rettime;
  }
  textDateOnly(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleDateString(locales);
  }
  textTimeOnly(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    // return time in locale format without seconds, no leading zero on hour
    return tDate.toLocaleTimeString(locales, {
      hour: "numeric",
      minute: "2-digit",
    });
  }
  textDayOnly(ts: number) {
    // return day of the week in locale format (local language)
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleDateString(locales, { weekday: "long" });
  }
  textOnly(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleString(locales, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
  }
  textYearOnly(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleDateString(locales, { year: "numeric" });
  }
  textDayOfMonth(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleDateString(locales, { day: "numeric" });
  }
  textMonthShort(ts: number) {
    let locales: any;
    if (navigator && navigator.languages) locales = navigator.languages;
    let tDate = new Date(ts);
    return tDate.toLocaleDateString(locales, { month: "short" });
  }

  roundTo(val, rounder) {
    return rounder * Math.round(val / rounder);
  }
  numSort(numArr: number[]) {
    return numArr.sort(function (a, b) {
      return a - b;
    });
  }
  timeText(sec_num: number, slim?: boolean) {
    if (sec_num < 0) sec_num = 0;
    let time: string = "";
    let hours: number = Math.floor(sec_num / 3600);
    let minutes: number = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: number = sec_num - hours * 3600 - minutes * 60;

    // if (hours && hours < 10 && !slim) {
    // 	time += "0";
    // }
    if (hours) time += hours.toString() + ":";
    if (hours && minutes < 10 && (hours || !slim)) {
      time += "0";
    }
    time += minutes.toString() + ":";
    if (seconds < 10) {
      time += "0";
    }
    time += seconds.toString();
    return time;
  }
  randomCode(length: number) {
    let chars = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";
    let result = "";
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  arrStrToNumber(strArr: string[]) {
    let numArr: number[] = [];
    for (let text of strArr) numArr.push(Number(text));
    return numArr;
  }
  numListToArr(numList: string) {
    let numArr: number[] = [];
    for (let s of numList.split(",")) numArr.push(Number(s));
    numArr.sort(function (a: number, b: number) {
      return a - b;
    });
    return numArr;
  }
  copyObject(source: any) {
    return JSON.parse(JSON.stringify(source));
  }
  copyArray(arr: any[]) {
    return arr.map((a) => ({ ...a }));
  }
  wipeTime(wDate: Date) {
    wDate.setHours(0);
    wDate.setMinutes(0);
    wDate.setSeconds(0);
    wDate.setMilliseconds(0);
    return wDate;
  }
  wipeDate(wDate: Date) {
    wDate.setFullYear(1970);
    wDate.setMonth(0);
    wDate.setDate(1);
    wDate.setSeconds(0);
    wDate.setMilliseconds(0);
    return wDate;
  }
  getObjArray(obj: any) {
    var result = Object.keys(obj).map(function (key) {
      return obj[key];
    });
    return result;
  }
  shuffleArray(array: any[]) {
    if (array && array.length)
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    return array;
  }
  simplePassword() {
    var suitArr = this.shuffleArray(["clubs", "diamonds", "hearts", "spades"]);
    var rankArr = this.shuffleArray([
      "ace",
      "deuce",
      "trey",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "jack",
      "queen",
      "king",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ]);
    return rankArr[0] + suitArr[0];
  }
  kFormat(num: any) {
    if (!parseFloat(num)) return num;
    else if (num) {
      if (num < 1000 || (num < 10000 && num % 1000 != 0)) return num.toString();
      else {
        var suffix = "";
        var divisor = 1;
        if (num >= 1000) {
          suffix = "K";
          divisor = 1000;
        }
        if (num >= 1000000) {
          suffix = "M";
          divisor = 1000000;
        }
        num /= divisor;
        if (num < 10) num = Math.round(num * 100) / 100;
        else num = Math.round(num * 10) / 10;
        return num.toString() + suffix;
      }
    } else return "";
  }
  kBlinds = function (blinds: any) {
    var retValues: any = {};
    if (
      (!blinds.ante && blinds.sb >= 10000) ||
      (blinds.ante && blinds.sb >= 4000)
    ) {
      retValues.sb = this.kFormat(blinds.sb);
      retValues.bb = this.kFormat(blinds.bb);
      if (blinds.ante) retValues.ante = this.kFormat(blinds.ante);
    } else {
      retValues.sb = blinds.sb;
      retValues.bb = blinds.bb;
      if (blinds.ante) retValues.ante = blinds.ante;
    }
    return retValues;
  };
  getFontSizeStyle(wrapper: any, text: any, leadIcon: string) {
    let heightRatio = wrapper.offsetHeight / text.offsetHeight;
    let widthRatio = wrapper.offsetWidth / text.offsetWidth;
    let fontSize = Number(getComputedStyle(text)["font-size"].slice(0, -2));
    if (heightRatio < widthRatio) fontSize *= heightRatio * 0.9;
    else fontSize *= widthRatio * 0.9;
    return {
      "font-size": Math.floor(fontSize).toString() + "px",
    };
  }
  getPlayerSort(name: string) {
    if (name) return "000" + name.toUpperCase();
    else return null;
  }
  getObjectLength(obj: any) {
    return Object.keys(obj).length;
  }
  roundAward(award: number, roundTo: number, adjust?: boolean) {
    if (adjust) {
      if (award < 2.5) roundTo = 0.25;
      else if (award < 5) roundTo = 0.5;
      else if (award < 50) roundTo = 1;
      else if (award < 250) roundTo = 5;
      else if (award < 1500) roundTo = 25;
      else if (award < 5000) roundTo = 50;
      else if (award < 10000) roundTo = 100;
      else if (award < 50000) roundTo = 250;
      else if (award < 1000000) roundTo = 500;
      else roundTo = 1000;
    }

    if (Math.round(award / roundTo) * roundTo == 0) return roundTo;
    else {
      var retAward = Math.round(award / roundTo) * roundTo;
      if (roundTo < 1) retAward = this.cleanFloat(retAward);
      return retAward;
    }
  }
  cleanFloat(result) {
    result *= 10000;
    result = Math.round(result);
    return result / 10000;
  }
  tsToDateOnly(ts: number) {
    return this.wipeTime(new Date(ts));
  }
  tsToTimeOnly(ts: number) {
    return this.wipeDate(new Date(ts));
  }
  getPlayerCost(player: any, params: any) {
    let cost: number = 0;
    if (!params.rebuyCost) params.rebuyCost = 0;
    if (!params.addonCost) params.addonCost = 0;
    if (params.managePayouts) {
      if (params.buyin) cost += params.buyin;
      if (params.tournamentType != "freezeout") {
        if (player.addon) cost += params.addonCost;
        if (player.rebuys) cost += params.rebuyCost * player.rebuys;
      }
    }
    if (!cost) return 1;
    else return cost;
  }
  getKills(player: any, params: any) {
    if (params.trackKnockouts && player.kos) return player.kos;
    else return 0;
  }
  getLevelsStructure(levels: any[]) {
    let structure: any = {};
    for (var i = 0; i < levels.length; i++) {
      let level = levels[i];
      structure[level.key] = level;
    }
    return structure;
  }
  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    // Extract headers
    let header = Object.keys(array[0]).join(",") + "\r\n";
    str += header;

    // Extract data rows
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }
  exportCSV(leaderboard, filename) {
    const csvString = this.convertToCSV(leaderboard);

    const blob = new Blob([csvString], { type: "text/csv" });

    saveAs(blob, filename);
  }
}
