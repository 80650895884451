import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../providers/auth.service";
import { ProfileService } from "../providers/profile.service";
import { RoutingService } from "../providers/routing.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.scss"],
})
export class VerifyComponent implements OnInit {
  profSub: Subscription;
  constructor(
    private routing: RoutingService,
    private auth: AuthService,
    private profServ: ProfileService
  ) {}

  ngOnInit() {
    this.profServ.profile.subscribe((profileData) => {
      if (profileData.emailVerified) this.routing.toLobby();
    });
  }
  logout() {
    this.auth.logout();
  }
  verifyEmail() {}
  ngOnDestroy() {
    if (this.profSub) this.profSub.unsubscribe();
  }
}
