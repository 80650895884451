export const homeRoutes = {
  bg: "/bg",
  bn: "/bn",
  ca: "/ca",
  cs: "/cs",
  da: "/da",
  de: "/de",
  el: "/el",
  en: "/",
  es: "/es",
  et: "/et",
  fi: "/fi",
  fr: "/fr",
  gu: "/gu",
  hi: "/hi",
  hr: "/hr",
  hu: "/hu",
  id: "/id",
  it: "/it",
  ja: "/ja",
  kn: "/kn",
  ko: "/ko",
  lt: "/lt",
  lv: "/lv",
  ml: "/ml",
  mr: "/mr",
  ms: "/ms",
  nl: "/nl",
  no: "/no",
  pa: "/pa",
  pl: "/pl",
  "pt-BR": "/pt-BR",
  "pt-PT": "/pt-PT",
  ro: "/ro",
  ru: "/ru",
  sk: "/sk",
  sl: "/sl",
  sr: "/sr",
  sv: "/sv",
  ta: "/ta",
  te: "/te",
  th: "/th",
  tr: "/tr",
  uk: "/uk",
  vi: "/vi",
  "zh-CN": "/zh-CN",
};
