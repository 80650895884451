import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { AngularFireDatabase } from "@angular/fire/database";

export class FirebaseTransLoader implements TranslateLoader {
  constructor(private db: AngularFireDatabase) {}
  public getTranslation(lang: string, prefix: string = "translations/"): any {
    return this.db.object(`${prefix}${lang}`).valueChanges();
  }
}

import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from "@ngx-translate/core";
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return "";
  }
}
