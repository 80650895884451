import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { homeRoutes } from "../shared/home-routes";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { TrackingService } from "./tracking.service";

@Injectable()
export class RoutingService {
  openSub: boolean = false;
  forceEnglish: boolean = false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private tracking: TrackingService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        let routePath: string = event.url;
        if (routePath.length > 1 && routePath.substring(0, 2) == "/#") {
          routePath = routePath.substring(2);
          this.toPath(routePath.slice(1));
        }
      }
    });
  }
  getPath() {
    return this.router.url.slice(1);
  }
  toLobby() {
    this.tracking.screenView("lobby");
    this.router.navigate(["/lobby"]);
  }
  toMembers() {
    this.tracking.screenView("members");
    this.router.navigate(["/members"]);
  }
  toHome(path?: string) {
    this.tracking.screenView("home");
    if (path) {
      this.translate.use(path);
      this.translate.currentLang;
      this.router.navigate([homeRoutes[path]]);
    } else if (homeRoutes[this.translate.currentLang])
      this.router.navigate([homeRoutes[this.translate.currentLang]]);
    else this.router.navigate(["/"]);
  }
  toVerify() {
    this.router.navigate(["/verify"]);
  }
  toTournament(tournID: string) {
    this.tracking.toTournament(tournID);
    this.router.navigate(["/tournament/" + tournID]);
  }
  toSubscription() {
    this.tracking.screenView("subscription");
    this.router.navigate(["/subscription"]);
  }

  toCheckout(planID: string) {
    this.tracking.toCheckout(planID);
    this.router.navigate(["/subscription/" + planID]);
  }

  toPath(path: string) {
    this.tracking.screenView(path);
    this.router.navigate([path]);
  }
}
