<h1 mat-dialog-title>
  <span *ngIf="data.tableBreaking">{{ "BREAKING_TABLE" | translate }}</span>
  <span *ngIf="!data.tableBreaking">{{
    "MANUAL_SEAT_CHANGE" | translate
  }}</span>
</h1>
<div mat-dialog-content style="min-width: 600px" class="sm:block">
  <div *ngIf="data.tableBreaking" class="flex flex-row">
    <div class="w-1/2 flex flex-col items-center justify-center">
      <img src="/assets/img/badge-break-table.png" class="w-60 h-auto" />
    </div>
    <div class="w-1/2 flex flex-col items-center justify-center">
      <div class="h-1/5"><h2>Table</h2></div>
      <div class="h-4/5 font-extrabold text-bv-orange w-full">
        <app-resize-text [textString]="data.tableBreaking"></app-resize-text>
      </div>
    </div>
  </div>
  <table class="w-full">
    <th></th>
    <th></th>
    <th class="text-bvdarkblue-A300" *ngIf="!data.tableBreaking">
      {{ "TABLE" | translate }}
    </th>
    <th class="text-bvdarkblue-A300">{{ "SEAT" | translate }}</th>
    <th></th>
    <th>{{ "TABLE" | translate }}</th>
    <th>{{ "SEAT" | translate }}</th>
    <tr *ngFor="let move of data.playerMoves">
      <td><img class="h-10 w-10 rounded-full" src="{{ move.imgURL }}" /></td>
      <td>
        <h2 class="hidden sm:block">{{ move.dispName }}</h2>
        <h4 class="block sm:hidden">{{ move.dispName }}</h4>
      </td>
      <td class="text-bvdarkblue-A300 text-center" *ngIf="!data.tableBreaking">
        {{ move.from.table }}
      </td>
      <td class="text-bvdarkblue-A300 text-center">{{ move.from.seat }}</td>
      <td class="text-center">
        <mat-icon color="accent">arrow_forward</mat-icon>
      </td>
      <td class="text-xl font-medium text-center">{{ move.to.table }}</td>
      <td class="text-xl font-medium text-center">{{ move.to.seat }}</td>
    </tr>
  </table>
</div>

<div mat-dialog-actions>
  <app-close-dialog-button></app-close-dialog-button>
</div>
