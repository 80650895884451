import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-disconnected",
  templateUrl: "./disconnected.component.html",
  styleUrls: ["./disconnected.component.css"],
})
export class DisconnectedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
