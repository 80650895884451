import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ProfileService } from "./profile.service";
import { AuthService } from "./auth.service";

@Injectable()
export class CanActivateProfile implements CanActivate {
  constructor(private profServ: ProfileService, private auth: AuthService) {}

  canActivate() {
    if (this.profServ.latestProfile || this.auth.altRoute) return true;
    else return false;
  }
}
